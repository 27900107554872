import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const KeyTermComparisonLoadingIcon = (props) => (<SvgIcon preserveAspectRatio="none" viewBox="0 0 843 270" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.8">
      <rect x="1" y="1.5791" width="265" height="77" fill="#F8F8FA"/>
      <rect x="1" y="1.5791" width="265" height="77" stroke="white" strokeWidth="1.72468"/>
    </g>
    <g opacity="0.8">
      <rect x="1" y="78.5791" width="265" height="91" fill="#F8F8FA"/>
      <rect x="1" y="78.5791" width="265" height="91" stroke="white" strokeWidth="1.72468"/>
    </g>
    <g opacity="0.8">
      <rect x="1" y="169.579" width="265" height="99" fill="#F8F8FA"/>
      <rect x="1" y="169.579" width="265" height="99" stroke="white" strokeWidth="1.72468"/>
    </g>
    <g opacity="0.8">
      <rect x="266" y="1.5791" width="110" height="124" fill="#F8F8FA"/>
      <rect x="266" y="1.5791" width="110" height="124" stroke="white" strokeWidth="1.72468"/>
      <rect x="376" y="1.5791" width="192" height="124" fill="#F8F8FA"/>
      <rect x="376" y="1.5791" width="192" height="124" stroke="white" strokeWidth="1.72468"/>
      <rect x="568" y="1.5791" width="137" height="124" fill="#F8F8FA"/>
      <rect x="568" y="1.5791" width="137" height="124" stroke="white" strokeWidth="1.72468"/>
      <rect x="705" y="1.5791" width="137" height="124" fill="#F8F8FA"/>
      <rect x="705" y="1.5791" width="137" height="124" stroke="white" strokeWidth="1.72468"/>
    </g>
    <g opacity="0.8">
      <rect x="266" y="125.579" width="212" height="35.75" fill="#F8F8FA"/>
      <rect x="266" y="125.579" width="212" height="35.75" stroke="white" strokeWidth="1.72468"/>
      <rect x="266" y="161.329" width="212" height="35.75" fill="#F8F8FA"/>
      <rect x="266" y="161.329" width="212" height="35.75" stroke="white" strokeWidth="1.72468"/>
      <rect x="266" y="197.079" width="212" height="35.75" fill="#F8F8FA"/>
      <rect x="266" y="197.079" width="212" height="35.75" stroke="white" strokeWidth="1.72468"/>
      <rect x="266" y="232.829" width="212" height="35.75" fill="#F8F8FA"/>
      <rect x="266" y="232.829" width="212" height="35.75" stroke="white" strokeWidth="1.72468"/>
      <rect x="478" y="125.579" width="121.333" height="47.6667" fill="#F8F8FA"/>
      <rect x="478" y="125.579" width="121.333" height="47.6667" stroke="white" strokeWidth="1.72468"/>
      <rect x="599.333" y="125.579" width="121.333" height="47.6667" fill="#F8F8FA"/>
      <rect x="599.333" y="125.579" width="121.333" height="47.6667" stroke="white" strokeWidth="1.72468"/>
      <rect x="720.667" y="125.579" width="121.333" height="47.6667" fill="#F8F8FA"/>
      <rect x="720.667" y="125.579" width="121.333" height="47.6667" stroke="white" strokeWidth="1.72468"/>
      <rect x="478" y="173.246" width="121.333" height="47.6667" fill="#F8F8FA"/>
      <rect x="478" y="173.246" width="121.333" height="47.6667" stroke="white" strokeWidth="1.72468"/>
      <rect x="599.333" y="173.246" width="121.333" height="47.6667" fill="#F8F8FA"/>
      <rect x="599.333" y="173.246" width="121.333" height="47.6667" stroke="white" strokeWidth="1.72468"/>
      <rect x="720.667" y="173.246" width="121.333" height="47.6667" fill="#F8F8FA"/>
      <rect x="720.667" y="173.246" width="121.333" height="47.6667" stroke="white" strokeWidth="1.72468"/>
      <rect x="478" y="220.912" width="95.5" height="47.6667" fill="#F8F8FA"/>
      <rect x="478" y="220.912" width="95.5" height="47.6667" stroke="white" strokeWidth="1.72468"/>
      <rect x="573.5" y="220.912" width="107" height="47.6667" fill="#F8F8FA"/>
      <rect x="573.5" y="220.912" width="107" height="47.6667" stroke="white" strokeWidth="1.72468"/>
      <rect x="680.5" y="220.912" width="95.5" height="47.6667" fill="#F8F8FA"/>
      <rect x="680.5" y="220.912" width="95.5" height="47.6667" stroke="white" strokeWidth="1.72468"/>
      <rect x="776" y="220.912" width="66" height="47.6667" fill="#F8F8FA"/>
      <rect x="776" y="220.912" width="66" height="47.6667" stroke="white" strokeWidth="1.72468"/>
    </g>
  </SvgIcon>);
