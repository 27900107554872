import { __rest } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import { ToolTip } from '@groupby/ui-components';
import { StyledTruncatedTooltipContainer } from './truncated-tooltip.styles';
export const TruncatedTooltip = (_a) => {
    var { children, title, vertical = false } = _a, props = __rest(_a, ["children", "title", "vertical"]);
    const textRef = useRef(null);
    const [isTooltip, setIsTooltip] = useState(false);
    useEffect(() => {
        const checkTruncate = () => {
            if (textRef.current) {
                const isHorizontalOverflowing = textRef.current.offsetWidth < textRef.current.scrollWidth;
                const isVerticalOverflowing = textRef.current.scrollHeight > textRef.current.offsetHeight;
                setIsTooltip(isHorizontalOverflowing || isVerticalOverflowing);
            }
        };
        checkTruncate();
        window.addEventListener('resize', checkTruncate);
        return () => window.removeEventListener('resize', checkTruncate);
    }, [children]);
    return (<ToolTip title={isTooltip ? title : ''} {...props}>
      <StyledTruncatedTooltipContainer vertical={vertical} ref={textRef}>
        {children}
      </StyledTruncatedTooltipContainer>
    </ToolTip>);
};
