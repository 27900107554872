import React from 'react';
import { baseColors, Icon, Typography } from '@groupby/ui-components';
import { InsightContainer, InsightDetails, InsightSuggestion, InsightTitleTypography, } from './insight.styles';
import { useLocalization } from '@/localization';
import { TruncatedTooltip } from '@/components/truncated-tooltip';
const TOOLTIP_STYLES_OVERRIDES = { tooltip: { sx: { maxWidth: '240px' } } };
export const Insight = (props) => {
    const { formatMessage: t } = useLocalization();
    const messageTitleKey = `INSIGHTS_${props.actionableInsightType.toUpperCase()}_TITLE`;
    const messageKey = `INSIGHTS_${props.actionableInsightType.toUpperCase()}`;
    return (<InsightContainer>
      <TruncatedTooltip arrow title={t({ key: messageTitleKey })}>
        <InsightTitleTypography>{t({ key: messageTitleKey })}</InsightTitleTypography>
      </TruncatedTooltip>
      {[0, 1].map((index) => {
        var _a;
        const details = props.details[index] || { rank: index, delta: '', text: '' };
        return (<InsightDetails key={details.rank} className='insight-details' $isDeltaNegative={(_a = details.delta) === null || _a === void 0 ? void 0 : _a.includes('-')}>
            {details.delta && (<React.Fragment>
                <Icon variant="arrowPaginationNext" color={baseColors.primaryBlueMed400}/>
                <Typography className="insight-delta">{details.delta.replace('-', '')}</Typography>
              </React.Fragment>)}
            <TruncatedTooltip title={<span>{details.text}</span>} placement="bottom-start" componentsProps={TOOLTIP_STYLES_OVERRIDES} arrow>
              <span className="insight-text">
                <Typography>{details.text}</Typography>
              </span>
            </TruncatedTooltip>
          </InsightDetails>);
    })}
      <TruncatedTooltip arrow vertical={true} componentsProps={TOOLTIP_STYLES_OVERRIDES} title={<div>
          <span>{t({ key: 'INSIGHTS_SUGGESTION' })}{': '}</span>
          <span>{t({ key: messageKey })}</span>
        </div>}>
        <InsightSuggestion>
          <Typography className="insight-suggestion-drop-cap">
            {t({ key: 'INSIGHTS_SUGGESTION' })}{': '}
          </Typography>
          <Typography>
            {t({ key: messageKey })}
          </Typography>
        </InsightSuggestion>
      </TruncatedTooltip>
    </InsightContainer>);
};
