import React from 'react';
import { baseColors, Icon, Skeleton } from '@groupby/ui-components';
import { Button } from '@/components/button';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, StyledNoDataFallback, StyledTitleTypography, } from './actionable-insights.styles';
import { useLocalization } from '@/localization';
import { Insight } from './partials/insight';
import { useActionableInsightData } from './useActionableInsightData';
import { DashboardEmptyIcon } from '@/components/icons';
export const ActionableInsights = (props) => {
    const { formatMessage: t } = useLocalization();
    const { data, loading, isModuleEnabled } = useActionableInsightData(props);
    const renderContent = () => {
        if (loading) {
            return (<Skeleton height="13rem" sx={{ width: '100%' }} animation="wave" variant="rectangular"/>);
        }
        if (data.length === 0) {
            return (<StyledNoDataFallback title={t({ key: 'NO_DATA_AVAILABLE' })} className="content" icon={<DashboardEmptyIcon />}/>);
        }
        return (data.map((insight) => (<Insight key={insight.actionableInsightType} {...insight}/>)));
    };
    if (!isModuleEnabled)
        return null;
    return (<div className="actionable-insights">
      <StyledAccordion defaultExpanded>
        <StyledAccordionSummary expandIcon={(!loading || data.length > 0) && (<Button icon={<Icon variant="chevronDown" color={baseColors.primaryBlueMed400}/>}/>)}>
          <StyledTitleTypography>
            {String(t({ key: 'INSIGHTS' })).toUpperCase()}
          </StyledTitleTypography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {renderContent()}
        </StyledAccordionDetails>
      </StyledAccordion>
    </div>);
};
