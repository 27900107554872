import styled, { css } from 'styled-components';
export const StyledTruncatedTooltipContainer = styled.div `
    ${({ vertical }) => (vertical
    ? css `
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
      `
    : css `
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `)}
`;
